<template>
  <tr>
    <td class='py-4 px-3 text-left'>{{entity.entity_name}}</td>
    <td class='py-4 px-3 text-left'>{{clientName}}</td>
    <td class='py-4 px-3 text-left'>{{entity.entity_type}}</td>
    <td class='py-4 px-3 text-left'>{{surtaxText}}</td>
    <td class='py-4 px-3 text-left'>{{entity.currency}}</td>
    <td class='py-4 px-3 text-left'>{{entity.business_license_number}}</td>
    <td class='py-4 px-3 text-left'>{{entity.address}}</td>
  </tr>
</template>

<script>
export default {
  name: 'EntitiesRowCaseMode',
  props: [
    'entity'
  ],
  computed: {
    surtaxText () {
      return (this.entity.is_surtax) ? '여' : '부'
    },
    clientName () {
      return (this.entity.client_name) ? this.entity.entity_name : this.entity.client_name
    },
  },
}
</script>
